<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="800px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title :title="currentfile.file_name">
          <!-- //! ชื่อไฟล์ที่เปิดอยู๋ -->
          <v-layout row>
            <v-flex
              lg11
              style="text-overflow:ellipsis;overflow:hidden;white-space:nowrap;"
            >
              <v-avatar
                :color="color.theme"
                :dark="color.darkTheme"
                size="36"
                class="mr-4"
              >
                <v-icon :dark="color.darkTheme">mdi-file-plus</v-icon>
              </v-avatar>
              <span
                >{{ $t("tagVersion.addfiletitle") }}
                {{ currentfile.file_name }}</span
              >
            </v-flex>
          </v-layout>
        </v-card-title>
        <!-- style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:4px;" -->
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-layout row justify-center wrap class="mt-6">
            <v-flex lg12 v-if="createprogress === false">
              <v-layout row wrap class="elevation-0" justify-center fill-height>
                <!-- //! ฝั่งของโฟลเดอร์ -->
                <v-flex lg5>
                  <v-layout row wrap align-center justify-center>
                    <v-flex lg2 class="text-center">
                      <v-btn
                        :disabled="enableback"
                        @click="
                          fn_loadfileandfolder_scrolling_child(parentdirectory),
                            fn_default(),
                            (targetfileObj = {})
                        "
                        x-small
                        fab
                        depressed
                        class="ml-4"
                        :color="color.theme"
                      >
                        <v-icon :color="color.chipText"
                          >keyboard_backspace</v-icon
                        >
                      </v-btn>
                    </v-flex>
                    <v-flex lg10 class="text-left">
                      <!-- //! ชื่อโฟลเดอร์ที่อยู่ -->
                      <v-card-text
                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:4px"
                        >{{ parentname }}</v-card-text
                      >
                    </v-flex>
                  </v-layout>
                  <v-list nav dense flat>
                    <v-list-item-group v-if="totaldata.totalfolder > 0">
                      <v-list-item
                        v-for="(item, index) in rootfile.filter((item) => {
                          return (
                            item.file_type === 'folder' ||
                            item.type === 'folder'
                          );
                        })"
                        :key="index"
                        @dblclick="
                          fn_checkPassWord(item.status_lock_data, item.file_id)
                        "
                      >
                        <!-- fn_CheckPasswordForFolder(item.status_lock_data, item.file_id) -->
                        <!-- fn_loadfileandfolder_scrolling_child(item.file_id), fn_default(), (targetfileObj = {}), -->
                        <v-list-item-icon>
                          <v-badge
                            color="error"
                            icon="mdi-lock"
                            overlap
                            v-if="item.status_lock_data == 'Y'"
                            bordered
                          >
                            <v-icon :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                          </v-badge>
                          <v-icon :color="item.file_icon[1]" v-else>{{
                            item.file_icon[0]
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.file_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                    <v-list-item-group v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class=""
                            v-text="$t('tagVersion.nofolder')"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-flex>
                <!-- //! ฝั่งของไฟล์ -->
                <v-flex lg5>
                  <v-layout>
                    <v-flex>
                      <!-- //! ชื่อไฟล์ที่เลือก -->
                      <v-card-text
                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:4px"
                        :title="targetfileObj.file_name"
                        >{{ $t("tagVersion.selectfile") }}
                        {{ targetfileObj.file_name }}</v-card-text
                      >
                    </v-flex>
                  </v-layout>
                  <v-list nav dense flat>
                    <v-list-item-group
                      v-model="targetfileIndex"
                      v-if="totaldata.totalfile - countrepeat > 0"
                      @change="fn_targetfileindexchange"
                    >
                      <v-list-item
                        v-for="(item, index) in rootfile.filter((item) => {
                          return (
                            (item.file_type !== 'folder' ||
                              item.type !== 'folder') &&
                            item.file_status !== 'M'
                          );
                        })"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-badge
                            color="error"
                            icon="mdi-lock"
                            overlap
                            v-if="item.status_lock_data == 'Y'"
                            bordered
                          >
                            <v-icon :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                          </v-badge>
                          <v-icon :color="item.file_icon[1]" v-else>{{
                            item.file_icon[0]
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="
                              targetfileObj.file_id == item.file_id
                                ? 'red--text'
                                : ''
                            "
                            >{{ item.file_name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                    <v-list-item-group v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class=""
                            v-text="$t('tagVersion.nofile')"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex lg12 v-else>
              <v-layout justify-center row wrap fill-height align-center>
                <v-progress-circular
                  class="mt-6 mb-6 pt-6 pb-6"
                  :size="50"
                  :dark="color.darkTheme"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- //! ตัว loader เป็น lib นอก -->
          <scroll-loader
            class="pa-0"
            :loader-method="fn_loadfileandfolder_scrolling"
            :loader-disable="disablescrolling"
          >
          </scroll-loader>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- //! close button -->
          <v-btn
            color="error"
            @click="
              $emit('closecurrentonly'), (targetfileObj = {}), fn_default()
            "
            >{{ $t("tagVersion.closebtn") }}</v-btn
          >
          <!-- //! submit button -->
          <v-btn
            class="white--text"
            :color="color.theme"
            @click="fn_checkSubmit()"
            :disabled="targetfileObj.file_name == undefined"
            >{{ $t("tagVersion.submitbtn") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage"
                >{{ $t("tagVersion.addfiletitle") }}
                <span v-if="resolutionScreen >= 400">{{ currentfile.file_name.length > 40 ? currentfile.file_name.substring(0, 40) + "..." : currentfile.file_name }}</span>
                <span v-else>{{ currentfile.file_name.length > 15 ? currentfile.file_name.substring(0, 15) + "..." : currentfile.file_name }}</span>
                </span
              >
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  $emit('closecurrentonly'), (targetfileObj = {}), fn_default()
                "
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-chip
            v-if="createprogress === false"
            :style="headerChipPage"
            :color="$vuetify.theme.dark ? '#989898' : color.theme"
          >
            <v-list-item-avatar
              :disabled="enableback"
              @click="
                fn_loadfileandfolder_scrolling_child(parentdirectory),
                  fn_default(),
                  (targetfileObj = {})
              "
              class="text-center"
            >
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : 'white'"
                dark
                size="25"
                >mdi-arrow-left-circle</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-title class="ml-n2">
              <span :style="headerPageMobile" v-if="resolutionScreen >= 400">
                {{ parentname }}</span
              >
              <span :style="headerPageMobile" v-else>{{
                parentname.length >= 30
                  ? parentname.substring(0, 30) + "..."
                  : parentname
              }}</span>
            </v-list-item-title>
          </v-chip>
        </v-card-text>
        <v-card-text style="height: 400px;">
          <v-layout row wrap>
            <v-flex v-if="createprogress === false">
              <!-- ฝั่งของโฟลเดอร์ -->
              <v-list nav dense v-if="totaldata.totalfolder > 0">
                <v-list-item-group :color="color.theme">
                  <v-list-item
                    v-for="(item, index) in rootfile.filter((item) => {
                      return (
                        item.file_type === 'folder' || item.type === 'folder'
                      );
                    })"
                    :key="index"
                    @click="
                      fn_checkPassWord(item.status_lock_data, item.file_id)
                    "
                  >
                    <v-list-item-icon>
                      <v-badge
                        color="error"
                        icon="mdi-lock"
                        overlap
                        v-if="item.status_lock_data == 'Y'"
                        bordered
                      >
                        <v-icon :color="item.file_icon[1]">{{
                          item.file_icon[0]
                        }}</v-icon>
                      </v-badge>
                      <v-icon :color="item.file_icon[1]" v-else>{{
                        item.file_icon[0]
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.file_name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list nav dense v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-center"
                      v-text="$t('tagVersion.nofolder')"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- ฝั่งของไฟล์ -->
              <v-layout row wrap justify-center class="pl-4">
                <v-flex>
                  <!-- //! ชื่อไฟล์ที่เลือก -->
                  <v-card-text
                    class="pr-4"
                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:4px"
                    >{{ $t("tagVersion.selectfile") }}
                    {{ targetfileObj.file_name }}</v-card-text
                  >
                </v-flex>
              </v-layout>
              <v-list nav dense v-if="totaldata.totalfile - countrepeat > 0">
                <v-list-item-group
                  v-model="targetfileIndex"
                  @change="fn_targetfileindexchange"
                  :color="color.theme"
                >
                  <v-list-item
                    v-for="(item, index) in rootfile.filter((item) => {
                      return (
                        (item.file_type !== 'folder' ||
                          item.type !== 'folder') &&
                        item.file_status !== 'M'
                      );
                    })"
                    :key="index"
                  >
                    <v-list-item-icon>
                      <v-badge
                        color="error"
                        icon="mdi-lock"
                        overlap
                        v-if="item.status_lock_data == 'Y'"
                        bordered
                      >
                        <v-icon :color="item.file_icon[1]">{{
                          item.file_icon[0]
                        }}</v-icon>
                      </v-badge>
                      <v-icon :color="item.file_icon[1]" v-else>{{
                        item.file_icon[0]
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        :class="
                          targetfileObj.file_id == item.file_id
                            ? 'red--text'
                            : ''
                        "
                        >{{ item.file_name }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list nav dense v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-center"
                      v-text="$t('tagVersion.nofile')"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex v-else>
              <v-layout justify-center>
                <br />
                <v-progress-circular
                  class="mt-6 mb-6 pt-6 pb-6"
                  :size="50"
                  :dark="color.darkTheme"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- //! ตัว loader เป็น lib นอก -->
          <scroll-loader
            class="pa-0"
            :loader-method="fn_loadfileandfolder_scrolling"
            :loader-disable="disablescrolling"
          >
          </scroll-loader>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-2"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="
              $emit('closecurrentonly'), (targetfileObj = {}), fn_default()
            "
            :disabled="createprogress"
            >{{ $t("tagVersion.closebtn") }}</v-btn
          >
          <v-btn
            class="elevation-0 white--text"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            @click="fn_checkSubmit()"
            :disabled="targetfileObj.file_name == undefined"
          >
            {{ $t("tagVersion.submitbtn") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- //! Check Password Dialog Go To Directoey-->
    <v-dialog
      persistent
      max-width="350"
      v-if="resolutionScreen >= 500"
      v-model="opendialog_checkpassword"
    >
      <v-card>
        <v-card-title>
          <v-layout row>
            <v-flex
              lg11
              style="text-overflow:ellipsis;overflow:hidden;white-space:nowrap;"
            >
              <v-icon color="red">lock</v-icon>
              <span>
                {{ $t("checkpassword.inputpassword") }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout class="mt-12" justify-center align-center row wrap>
            <v-flex align-self-center xs11 lg12>
              <v-spacer></v-spacer>
              <v-text-field
                :error-messages="tryagain ? 'wrong password' : ''"
                v-model="password"
                persistent-hint
                multiple
                outlined
                dense
                @click:append="showpassword = !showpassword"
                :type="showpassword ? 'text' : 'password'"
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            dark
            @click="opendialog_checkpassword = false"
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            dark
            :color="color.theme"
            @click="fn_gotodirectory(), (password = ''), (showpassword = false)"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
      v-model="opendialog_checkpassword"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("checkpassword.inputpassword")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  (opendialog_checkpassword = false),
                    (password = ''),
                    (showpassword = false)
                "
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{
            $t("checkpassword.inputpassword")
          }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="password"
            height="35px"
            @click:append="showpassword = !showpassword"
            multiple
            :type="showpassword ? 'text' : 'password'"
            :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </v-card-text>
        <div class="text-center pb-4 mt-n2">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="
              (opendialog_checkpassword = false),
                (password = ''),
                (showpassword = false)
            "
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="fn_gotodirectory(), (showpassword = false)"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- //! Check Password Submit File-->
    <v-dialog
      persistent
      max-width="350"
      v-if="resolutionScreen >= 500"
      v-model="opendialog_checkpasswordsubmit"
    >
      <v-card>
        <v-card-title>
          <v-layout row>
            <v-flex
              lg11
              style="text-overflow:ellipsis;overflow:hidden;white-space:nowrap;"
            >
              <v-icon color="red">lock</v-icon>
              <span>
                {{ $t("checkpassword.inputpassword") }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout class="mt-12" justify-center align-center row wrap>
            <v-flex align-self-center xs11 lg12>
              <v-spacer></v-spacer>
              <v-text-field
                :error-messages="tryagain ? 'wrong password' : ''"
                v-model="password"
                persistent-hint
                multiple
                outlined
                dense
                @click:append="showpassword = !showpassword"
                :type="showpassword ? 'text' : 'password'"
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            dark
            @click="opendialog_checkpasswordsubmit = false"
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            dark
            :color="color.theme"
            @click="
              fn_checkPassWordSubmit(), (password = ''), (showpassword = false)
            "
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
      v-model="opendialog_checkpasswordsubmit"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("checkpassword.inputpassword")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  (opendialog_checkpasswordsubmit = false),
                    (password = ''),
                    (showpassword = false)
                "
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{
            $t("checkpassword.inputpassword")
          }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            height="35px"
            v-model="password"
            multiple
            outlined
            dense
            @click:append="showpassword = !showpassword"
            :type="showpassword ? 'text' : 'password'"
            :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </v-card-text>
        <div class="text-center pb-4 mt-n2">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="
              (opendialog_checkpasswordsubmit = false),
                (password = ''),
                (showpassword = false)
            "
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="
              fn_checkPassWordSubmit(), (password = ''), (showpassword = false)
            "
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  filters: {
    subStr: function(val) {
      if (val.length > 15) return val.substring(0, 15) + "...";
      else return val;
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          (this.targetfileIndex = ""),
            (this.targetfileObj = {}),
            this.fn_querydirectories(this.parentfolder);
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน chip ของ mobile
    headerPageMobile() {
      return "color:white;" + "font-size: 16px; line-height: 24px;";
    },
    // style chip ของ mobile
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    }
  },
  props: ["show", "currentfile", "parentfolder"],
  data() {
    return {
      createprogress: false,
      listdirectory: [],
      page: 1,
      size: 30,
      rootfile: [],
      parentdirectory: "",
      enableback: false,
      disablescrolling: true,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      targetfileIndex: "",
      targetfileObj: {},
      parentname: "",
      currentid: "",
      countrepeat: 0,
      password: "",
      showpassword: false,
      opendialog_checkpassword: false,
      opendialog_checkpasswordsubmit: false,
      gotolockfolder: "",
      tryagain: false,
    };
  },
  watch: {
    // targetfileIndex(currentval, oldval) {
    //   // console.log("watch cur", currentval);
    //   // console.log("watch old", oldval);
    //   console.log('watch rootfile',this.rootfile)
    //   let returnItem = this.rootfile.filter((item) => {
    //     return item.file_type !== "folder" || (item.type !== "folder" && item.file_status !== "M");
    //   });
    //   console.log("return item", returnItem);
    //   if (currentval !== undefined) {
    //     this.targetfileObj = returnItem[currentval];
    //   } else {
    //     this.targetfileObj = returnItem[oldval];
    //   }
    //   console.log("OBJ", this.targetfileObj,this.parentfolder);
    // },
  },
  methods: {
    // เวลาคลิ๊กเลือกไฟล์แล้วให้เอา index ไปหาไฟล์แล้วเก็บลง obj
    // ใช้ watch แล้วเปิดซ้ำมันจะพังเพราะไม่ได้ destroy watcher
    fn_targetfileindexchange() {
      console.log("targetfileindexchange", this.targetfileIndex);
      let filterFileOnly = this.rootfile.filter((item) => {
        return (
          item.file_type !== "folder" ||
          (item.type !== "folder" && item.file_status !== "M")
        );
      });
      if (this.targetfileIndex !== undefined) {
        this.targetfileObj = filterFileOnly[this.targetfileIndex];
        // console.log("targetObj", this.targetfileObj);
      } else {
        // console.log("targetindex", this.targetfileIndex);
        // console.log("undefined index", this.targetfileObj);
      }
    },
    // ตั้งค่า default เวลามีการเข้า folder ใหม่
    fn_default() {
      // ปิดไปก่อน ถ้าเปิดเวลาโหลดไฟล์เวลา scroll มันจะรีไปโหลดครั้งแรกก่อน
      // this.totaldata = {
      //   totalall: 0,
      //   totalfile: 0,
      //   totalfolder: 0,
      // };
      // this.rootfile = [];
      // this.page = 1;

      // ปิดไม่ให้มัน scroll ได้เวลาโหลดครั้งแรก
      this.disablescrolling = true;
    },
    // ไปเรียก loadfileandfolder
    fn_querydirectories(parameter) {
      console.log("fn_querydirectories");
      this.createprogress = true;
      this.enableback = false;
      console.log("click:", parameter);
      setTimeout(() => {
        this.fn_loadfileandfolder_scrolling_child(parameter);
      }, 500);
    },
    // โหลดใหม่
    async fn_loadfileandfolder_scrolling_child(parameter) {
      console.log("New Load");
      this.createprogress = true;
      // แก้ rootfile.length ไม่เท่ากัน
      this.countrepeat = 0;
      // currentid เอาไว้จำ parameter เอาไว้ให้อีกตัวโหลดต่อ
      this.currentid = parameter;
      this.rootfile = [];
      let payload;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      console.log("cal offset", cal_offset);
      if (cal_offset == 0) {
        this.rootfile = [];
      }
      console.log(
        `Set parameter \ncurrentid:${this.currentid}\nrootfile:${this.rootfile}\npage:${this.page}\ncal limit:${cal_limit}\ncal offset:${cal_offset}`
      );
      if (this.dataAccountActive["type"] === "Business") {
        console.log(
          `business info folder id ${this.dataAccountActive["business_info"]["my_folder_id"]}`
        );
      }

      // Personal
      payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: parameter || "",
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      // console.log("add tag payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            this.rootfile = [];
            //? Folder Businsess
            //? กรณีที่ F.
            if (
              response.data.your_folder[0]["status"] === "R" &&
              this.dataAccountActive["type"] === "Business"
            ) {
              if (
                parameter ===
                this.dataAccountActive["business_info"]["my_folder_id"]
              ) {
                //? เข้ามาใน Business Drive จะเรียก Department folder ออกมา
                console.log("Business Drives/Department");
                for (
                  let index = 0;
                  index < this.dataAccountActive["directory_business"].length;
                  index++
                ) {
                  const element = this.dataAccountActive["directory_business"];
                  let datafolder = {};
                  datafolder["parent_folder_id"] =
                    element["my_folder_id"] ||
                    this.dataAccountActive["business_info"]["my_folder_id"];
                  datafolder["name_eng"] = element["first_name_eng"];
                  datafolder["name_th"] = element["first_name_th"];
                  datafolder["user_id"] = "";
                  //? เพิ่มเข้ามาสำหรับโฟลเดอร์ทีมี department
                  datafolder["file_name"] =
                    element["department_name"] ||
                    element[index]["department_name"];
                  datafolder["file_id"] =
                    element[index]["department_folder_id"];
                  datafolder["file_status"] = "M";
                  datafolder["file_status"] = "M";
                  datafolder["file_type"] = "folder";
                  datafolder["file_icon"] = ["mdi-folder-heart", "green"];
                  datafolder["status_updating"] = "Done";
                  this.rootfile.push(datafolder);
                }
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.enableback = false;
              } else if (
                this.dataAccountActive["directory_business"].findIndex(
                  (t) => t.department_folder_id === parameter
                ) >= 0
              ) {
                //?อยุ่ใน Department Folder
                console.log("Department Folder");
                this.parentdirectory = this.dataAccountActive["business_info"][
                  "my_folder_id"
                ];
                // ?console.log(this.parentdirectory);
                //? this.parentname = this.dataAccountActive["directory_business"][index_data]["department_name"];
                this.enableback = false;
              } else {
                //? อยู่ใน Root Business
                console.log("Root Business");
                // ?สร้าง folder บริษัท
                let datafolder = {};
                datafolder["file_id"] = this.dataAccountActive["business_info"][
                  "my_folder_id"
                ];
                datafolder[
                  "file_name"
                ] = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );
                datafolder["file_status"] = "M";
                datafolder["file_type"] = "folder";
                datafolder["file_icon"] = [
                  "mdi-folder-network",
                  "blue darken-4",
                ];
                datafolder["parent_folder_id"] = this.dataAccountActive[
                  "directory_id"
                ];
                datafolder["name_eng"] = this.dataAccountActive[
                  "business_info"
                ]["first_name_eng"];
                datafolder["name_th"] = this.dataAccountActive["business_info"][
                  "first_name_th"
                ];
                datafolder["user_id"] = "";
                this.rootfile.push(datafolder);
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.parentname = this.$t("toolbar.mydrive");
                // console.log(this.parentdirectory);
                this.enableback = true;
                // this.parentname = this.$t("toolbar.mydrive");
              }
            } else if (
              (response.data.your_folder[0]["full_path"] === "root" ||
                response.data.your_folder[0]["full_path"] === "Mainfolder") &&
              this.dataAccountActive["type"] === "Citizen"
            ) {
              //? Root Personal
              console.log("Root Personal");
              this.parentdirectory = this.dataAccountActive["directory_id"];
              this.parentname = this.$t("toolbar.mydrive");
              // console.log(this.parentdirectory);

              this.enableback = true;
            } else {
              console.log("else");
              this.parentname = response.data.your_folder[0]["name"];
              this.parentdirectory =
                response.data.your_folder[0]["folder_id"] === null
                  ? this.dataAccountActive["type"] === "Business"
                    ? this.dataAccountActive["business_info"]["my_folder_id"]
                    : this.dataAccountActive["directory_id"]
                  : response.data.your_folder[0]["folder_id"];

              // ?dataAccountActive["business_info"]["my_folder_id"] คือ id โฟลเดอร์บริษัท
              // ?dataAccountActive["directory_id"] คือ id ของ root business
              // ?your_folder.folder_id คือ id ของโฟลเดอร์ที่มันอยู่
              // ?your_folder.id คือ id ของตัวไฟล์/โฟลเดอร์

              //? เช็คว่า parameter กับ response.data.your_folder[0].id เป็นตัวเดียวกันไหม ถ้าใช่แสดงว่าอยู่ใน folder บริษัท
              if (
                response.data.your_folder[0].folder_id === null &&
                response.data.your_folder[0].id === parameter
              ) {
                console.log("Department Folder");
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.parentname = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );
                // ?สร้าง folder แผนก
                // ?ดักเผื่อไว้ เพราะมันเข้ามาทั้งของ citizen และ business ได้
                if (this.dataAccountActive["type"] === "Business") {
                  for (
                    var a = 0;
                    a < this.dataAccountActive["directory_business"].length;
                    a++
                  ) {
                    let datafolder = {};
                    datafolder["file_id"] = this.dataAccountActive[
                      "directory_business"
                    ][a]["department_folder_id"];
                    datafolder["file_name"] = this.dataAccountActive[
                      "directory_business"
                    ][a]["department_name"];
                    datafolder["file_size"] = "-";
                    datafolder["file_department"] = this.dataAccountActive[
                      "directory_business"
                    ][a]["department_id"];
                    datafolder["file_lastdtm"] = "-";
                    datafolder["file_owner_eng"] = "-";
                    datafolder["file_owner_th"] = "-";
                    datafolder["file_status"] = "M";
                    datafolder["file_type"] = "folder";
                    datafolder["file_icon"] = ["mdi-folder-heart", "green"];
                    datafolder["status_updating"] = "Done";
                    this.rootfile.push(datafolder);
                  }
                }
              }
              this.enableback = false;
            }
            // ?File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              if (response.data.data[i].id !== this.currentfile.file_id) {
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.fn_seticonNew(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_data"] =
                  response.data.data[i]["status_lock_data"];
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                this.rootfile.push(dataFileAndFolder);
              } else {
                this.countrepeat = 1;
              }
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              this.disablescrolling =
                this.totaldata["totalall"] <=
                this.rootfile.length + this.countrepeat;
            }
            this.createprogress = false;
            console.log("rootfile", this.rootfile);
            console.log("dis", this.disablescrolling);
            console.log("total data", this.totaldata.totalall);
            console.log("rootfile data", this.rootfile.length);

            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
            
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + " : " + response.data.errorMessage,
            });
            // status not OK
            this.disablescrolling = true;
            this.createprogress = false;
          }
        });
    },

    
    //! โหลดต่อ
    async fn_loadfileandfolder_scrolling() {
      console.log("Scroll");
      // this.createprogress = true
      let payload;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      if (cal_offset == 0) {
        this.rootfile = [];
      }
      //? Personal
      payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.currentid,
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            // ? File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              if (response.data.data[i].id !== this.currentfile.file_id) {
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.fn_seticonNew(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_data"] =
                  response.data.data[i]["status_lock_data"];
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                this.rootfile.push(dataFileAndFolder);
              } else {
                this.countrepeat = 1;
              }
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              this.disablescrolling =
                this.totaldata.totalall <=
                this.rootfile.length + this.countrepeat;
            }
            this.createprogress = false;
            console.log("disscroll", this.disablescrolling);
            console.log("rootfile", this.rootfile.length);
            console.log("data", this.totaldata.totalall);
            console.log("currentid", this.currentfile.file_id);

            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          } else {
            // ? status not OK
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + " : " + response.data.errorMessage,
            });
            this.disablescrolling = true;
            this.createprogress = false;
          }
        });
    },

    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      console.log("auth.code",auth.code);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },

    //! ตั้งค่า icon
    fn_seticonNew(_type, raw_parameter) {
      let dataicon;
      let parameter;
      if (typeof raw_parameter === "string") {
        parameter = raw_parameter.toLowerCase();
      } else {
        parameter = raw_parameter;
      }
      if (_type == "folder") {
        dataicon = ["folder", "#FDD361"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          // dataicon = ["mdi-file-excel", "green"];
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          // dataicon = ["mdi-file-powerpoint", "orange"];
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          // dataicon = ["mdi-file-word", "primary"];
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          //  dataicon = ["mdi-file-pdf", "red"];
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (parameter === "csv") {
          dataicon = ["mdi-file-table", "green"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png" ||
          parameter === "gif"
        ) {
          // dataicon = ["mdi-file-image", "blue-grey"];
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else if (parameter === "r" || parameter === "folder") {
          // dataicon = ["mdi-folder", "#FDD361"];
          dataicon = ["mdi-folder", "#ffc107"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      }
      return dataicon;
    },
    //! clear ค่า
    fn_cleardata() {
      this.listdirectory = [];
      this.page = 1;
      this.size = 30;
      this.rootfile = [];
      this.parentdirectory = "";
      this.enableback = false;
      this.disablescrolling = true;
      this.totaldata = { totalall: 0, totalfile: 0, totalfolder: 0 };
      this.targetfileIndex = "";
      this.targetfileObj = {};
      this.parentname = "";
    },
    //! ถ้า folder ไม่ได้ lock ให้เข้าได้แบบปกติ
    fn_checkPassWord(parameter_lock_data, parameter_current_id) {
      if (parameter_lock_data === "Y") {
        this.opendialog_checkpassword = true;
        this.gotolockfolder = parameter_current_id;
        console.log("lock go to", parameter_current_id);
        console.log("current id", this.currentid);
        this.password = "";
        this.showpassword = false;
      } else {
        this.fn_loadfileandfolder_scrolling_child(parameter_current_id),
          this.fn_default(),
          (this.targetfileObj = {});
      }
    },
    //! สำหรับ check password ตอนจะเข้าไปใน foldere ที่ lock
    async fn_gotodirectory() {
      // ? ถ้ารหัสผิดให้กลับมาโหลดหน้าเดิมแทนใช้ตัว currentid
      let auth = await gbfGenerate.generateToken();
      let payload = {
        account_id: this.dataAccountId,
        folder_id: this.gotolockfolder,
        status: "",
        user_id: this.dataUsername,
        password: this.password,
      };
      console.log("this.password", this.password);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/lock_file/search_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            //? รหัสถูก
            console.log("password correct");
            this.tryagain = false;
            this.fn_loadfileandfolder_scrolling_child(this.gotolockfolder);
            this.fn_default();
            this.targetfileObj = {};
            this.opendialog_checkpassword = false;
          } else {
            //? รหัสผิด
            console.log("password incorrect");
            //? โหลดหน้าเดิม
            // this.fn_loadfileandfolder_scrolling_child(this.currentid);
            // this.fn_default();
            // this.targetfileObj = {};
            // this.opendialog_checkpassword = false;
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + " : " + response.data.errorMessage,
            });
            //? ให้ใส่รหัสใหม่
            this.tryagain = true;
          }
        })
        .catch((err) => {
          console.log("catch err in fn_gotodirectory:", err);
        });
    },
    //! รอ api ใหม่ api ตัวนี้ของ file มันเป็นโหลด
    fn_checkSubmit() {
      console.log("targetObg submit", this.targetfileObj);
      //? targetfileObj ถ้าเป็น lock ต้องเช็คก่อนให้ไปทำ submit ปกติ
      if (this.targetfileObj.status_lock_data === "Y") {
        // ? ส่งไฟล์ lock ต้องใส่รหัสผ่านก่อน
        // console.log("file log");
        this.opendialog_checkpasswordsubmit = true;

        //? บังคับให้ส่งไปก่อน
        // this.$emit("closecurrentonly");
        // this.$emit("filerecive", this.targetfileObj);
        // this.fn_cleardata();
      } else {
        this.$emit("closecurrentonly");
        this.$emit("filerecive", this.targetfileObj);
        this.fn_cleardata();
      }
    },
    async fn_checkPassWordSubmit() {
      let payload = {
        account_id: this.dataAccountId,
        file_id: this.targetfileObj.file_id,
        password: this.password,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "api/get_status_lock_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("response checkpassword", response);
          if (response.data.status === "OK") {
            //?รหัสถูก
            console.log("password correct");
            this.$emit("filerecive", this.targetfileObj);
            this.$emit("closecurrentonly");
            this.fn_cleardata();
            this.tryagain = false;
            this.opendialog_checkpasswordsubmit = false;
          } else {
            //? รหัสผิด
            console.log("password incorrect");
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + " : " + response.data.errorMessage,
            });
            //? ให้ใส่รหัสใหม่
            this.tryagain = true;
          }
        })
        .catch((err) => {
          console.log("catch err in fn_checkPassWordSubmit:", err);
        });
    },
  },
};
</script>

<style>
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
